import React from "react";
import "./footer.css";
import { BsLinkedin, BsInstagram, BsFacebook } from "react-icons/bs";

const Footer = () => {
  return (
    <footer>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#wexperience">Work Experience</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        {/* <li>
          <a href="#contact">Contact</a>
        </li> */}
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/rohim48179/" target="_blank" rel="noreferrer">
          <BsLinkedin />
        </a>
        <a href="https://facebook.com/rohim48179" target="_blank" rel="noreferrer">
          <BsFacebook />
        </a>
        <a href="https://www.instagram.com/nr.him/" target="_blank" rel="noreferrer">
          <BsInstagram />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy; 2024 Noer Rohim. All Right reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
