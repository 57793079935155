import React from "react";
import "./nav.css";
import { AiOutlineHome, AiOutlineUser, AiOutlinePhone } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { HiOutlineFolderOpen } from "react-icons/hi";
import { useState } from "react";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav>
      <a
        href="#"
        onClick={() => setActiveNav("#")}
        className={activeNav === "#" ? "active" : ""} // if activeNav is #, then add class active. if not, then don't remove class active
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#wexperience"
        onClick={() => setActiveNav("#workexperience")}
        className={activeNav === "#workexperience" ? "active" : ""}
      >
        <BiBook />
      </a>
      <a
        href="#experience"
        onClick={() => setActiveNav("#experience")}
        className={activeNav === "#experience" ? "active" : ""}
      >
        <HiOutlineFolderOpen />
      </a>
      {/* <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <AiOutlinePhone />
      </a> */}
    </nav>
  );
};

export default Nav;
