import React from "react";
import { BsLinkedin, BsInstagram, BsFacebook } from "react-icons/bs";

const HeaderSocial = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/rohim48/" target="_blank" rel="noreferrer">
        <BsLinkedin />
      </a>
      <a href="https://facebook.com/rohim48179" target="_blank" rel="noreferrer">
        <BsFacebook />
      </a>
      <a href="https://www.instagram.com/nr.him/" target="_blank" rel="noreferrer">
        <BsInstagram />
      </a>
    </div>
  );
};

export default HeaderSocial;
