import React from 'react';
import './workexperience.css';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { BsCaretRightFill } from "react-icons/bs";

const WorkExperience = () => {
    return (
      <section id="wexperience">
        <h5>What Works Experience I Have</h5>
        <h2>My Works Experience</h2>
  
        <div className="container wexperience__container">
          <div className="wexperience__ai">
            <h3>PT Astra Honda Motor • Production Operator</h3>
            <h5>Casting Wheel Die Casting • Jul 2018 - Apr 2020</h5>
            <div className="wexperience__content">
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Cutting</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Trimming</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Chipping</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Inspection</h4>
                  <small className="text-light">Intermediate</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Report</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
            </div>
          </div>
          <div className="wexperience__frontend">
            <h3>PT Astra Honda Motor • Production Operator</h3>
            <h5>High Pressure Die Casting • Jul 2020 - Jul 2021</h5>
            <div className="wexperience__content">
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Trimming</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Buffing</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>HPDC Mechine</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Inspection</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Report</h4>
                  <small className="text-light">Intermediate</small>
                </div>
              </article>
              {/* <article className="wexperience__details">
                <BsFillPatchCheckFill className="wexperience__details-icon" />
                <div>
                  <h4>Report</h4>
                  <small className="text-light">Intermediate</small>
                </div>
              </article> */}
            </div>
          </div>
          <div className="wexperience__backend">
            <h3>CV Dua Mitratama • Sales & Marketing</h3>
            <h5>Marketing • Sep 2021 - Present</h5>
            <div className="wexperience__content">
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Product Knowlegde</h4>
                  <small className="text-light">Intermediate</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Social Media Marketing</h4>
                  <small className="text-light">Intermediate</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Website Advertising Developer</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Cold Calling</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsCaretRightFill className="wexperience__details-icon" />
                <div>
                  <h4>Customer Retention</h4>
                  <small className="text-light">Basic</small>
                </div>
              </article>
            </div>
          </div>
          {/* <div className="wexperience__media">
            <h3>Visual Communication Design</h3>
            <h5>5 Years Experience</h5>
            <div className="wexperience__content">
              <article className="wexperience__details">
                <BsFillPatchCheckFill className="wexperience__details-icon" />
                <div>
                  <h4>Logo Branding Design</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsFillPatchCheckFill className="wexperience__details-icon" />
                <div>
                  <h4>2D Motion Graphic</h4>
                  <small className="text-light">Certified</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsFillPatchCheckFill className="wexperience__details-icon" />
                <div>
                  <h4>Adobe After Effect</h4>
                  <small className="text-light">Intermediate</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsFillPatchCheckFill className="wexperience__details-icon" />
                <div>
                  <h4>Wondershare Filmora</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsFillPatchCheckFill className="wexperience__details-icon" />
                <div>
                  <h4>Adobe Photoshop</h4>
                  <small className="text-light">Intermediate</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsFillPatchCheckFill className="wexperience__details-icon" />
                <div>
                  <h4>Corel Draw</h4>
                  <small className="text-light">Experienced</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsFillPatchCheckFill className="wexperience__details-icon" />
                <div>
                  <h4>Adobe Premiere Pro</h4>
                  <small className="text-light">Intermediate</small>
                </div>
              </article>
              <article className="wexperience__details">
                <BsFillPatchCheckFill className="wexperience__details-icon" />
                <div>
                  <h4>Adobe Illustrator</h4>
                  <small className="text-light">Intermediate</small>
                </div>
              </article>
            </div>
          </div> */}
        </div>
      </section>
    );
  };
  
  export default WorkExperience;